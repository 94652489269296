import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getFirstLetterUpperCase(word: string) {
  const firstLetter = word[0].toUpperCase()
  return `${firstLetter}${word.slice(1).toLowerCase()}`
}
