export const home = {
  step0: `Welcome to the new and improved version of As-Is Online for offer creation. We keep improving and updating the co-worker tool. The first screen that you see here is completely new! We call it the offer waiting room. From now on you don't need to create your offers from scratch. If you have used the 'tick' box in SEKUND for online offers, then we pull the information automatically into this tool for you to update and publish`,
  step1: `NEW offers - In this table you can find all the offers that have been created in SEKUND, and that got the 'tick' Offer for Online Sales. From here you can easily publish the item online. Offers that come in also have the status NEW`,
  step2: `You are now able to easily select multiple items to Publish or to Unpublish. Click multiple boxes or use the top box to select all.`,
  step3: `With this button, you can easily publish or archive a selected item. Please make sure before posting that all required information has been added to the offer.`,
  step4: `With the Sekund ID, you can easily Open and update and item. Make sure that all information is there before publishing an item`,
  step5: `With this button, you can navigate back to the old version of the As-Is Online. We don't have all functionalities yet in this new version. But stay tuned!`,
} as const

export const editOffer = {
  'edit-offer-step1': `On the top of the offer form, you can find all meta data of the offer: Sekund ID, Product name and status`,
  'edit-offer-step2': `By clicking the back arrow, you are directed back to all the tabs without saving your changes`,
  'edit-offer-step3': `If you want to unpublish this offer quickly, click this button`,
  'edit-offer-step4': `If you have made changes to the offer, save them before closing with this button`,
  'edit-offer-step5': `With these tabs you can switch between product detail information, images (where you can upload the defect images) and the activities that happened on the offer`,
  'edit-offer-step6': `Reason for discount is a mandatory field, before closing please select the reason for discount`,
} as const
