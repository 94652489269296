import { useTranslation } from 'next-i18next'

import { Text } from '~/components/text'

export function StepContent({
  step,
  defaultValue,
}: {
  step: string
  defaultValue: string
}) {
  const { t } = useTranslation()

  return <Text>{t(step, defaultValue)}</Text>
}
