import { useRouter } from 'next/router'
import { useCallback } from 'react'

export enum InventoryTabKey {
  WaitingRoom = 'waiting-room-panel',
  Published = 'published-panel',
  Reserved = 'reserved-panel',
  Sold = 'sold-panel',
  Unpublished = 'unpublished-panel',
}

export function useActiveInventoryTabStore() {
  const router = useRouter()
  // only when router is ready we can know if router.query.tab is defined or not, in case it is not, we set a default value.
  const activeTab = router.isReady
    ? router.query.tab
      ? (router.query.tab as InventoryTabKey)
      : InventoryTabKey.WaitingRoom
    : (router.query.tab as InventoryTabKey)
  const setActiveTab = useCallback(
    (tab: InventoryTabKey) => {
      // set tab and delete selecteItems params
      const newQuery = router.query
      newQuery.tab = tab
      // check that it has changed, to see if it is a change between tabs and not a page reload.
      if (tab !== activeTab) delete newQuery.selectedItems
      router.push({ query: newQuery })
    },
    [router],
  )
  return {
    activeTab,
    setActiveTab,
  }
}
