import { create } from 'zustand'

export enum FormTabKey {
  Details = 'details',
  Images = 'images',
  Activities = 'activities',
}
export const useActiveFormTabStore = create<{
  activeTab: FormTabKey
  setActiveTab: (activeTab: FormTabKey) => void
}>()((set) => ({
  activeTab: FormTabKey.Details,
  setActiveTab: (activeTab) => set({ activeTab }),
}))
