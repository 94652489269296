import { InventoryTabKey } from '../inventory/use-active-inventory-tab-store'
import { FormTabKey } from '../offer/sidebar-drawer/use-active-form-tab-store'
import { editOffer, home } from './content'
import { StepContent } from './step-content'
import { stepStyles } from './step-styles'

export const styles = stepStyles
interface Step {
  selector: string
  title: string
  content: JSX.Element
  action?: (target: Element | null) => void
}
export const editOfferSteps: Step[] = [
  {
    selector: '[data-step="edit-offer-step-1"]',
    title: 'Step 1 - Meta data',
    content: (
      <StepContent
        step="edit-offer-step1"
        defaultValue={editOffer['edit-offer-step1']}
      />
    ),
  },
  {
    selector: '[data-step="edit-offer-step-2"]',
    title: 'Step 2 - Go back',
    content: (
      <StepContent
        step="edit-offer-step2"
        defaultValue={editOffer['edit-offer-step2']}
      />
    ),
  },
  {
    selector: '[data-step="edit-offer-step-3"]',
    title: 'Step 3 - Unpublish',
    content: (
      <StepContent
        step="edit-offer-step3"
        defaultValue={editOffer['edit-offer-step3']}
      />
    ),
  },
  {
    selector: '[data-step="edit-offer-step-4"]',
    title: 'Step 4 - Save changes',
    content: (
      <StepContent
        step="edit-offer-step4"
        defaultValue={editOffer['edit-offer-step4']}
      />
    ),
  },
  {
    selector: '.overflow-carousel',
    title: 'Step 5 - Tabs',
    content: (
      <StepContent
        step="edit-offer-step5"
        defaultValue={editOffer['edit-offer-step5']}
      />
    ),
    action: (node) => {
      const detailTab = node?.querySelector(
        `[aria-controls="${FormTabKey.Details}"]`,
      ) as HTMLButtonElement
      detailTab.click()
    },
  },
  {
    selector: '[data-step="edit-offer-step-6"]',
    title: 'Step 6 - Reason for Discount',
    content: (
      <StepContent
        step="edit-offer-step6"
        defaultValue={editOffer['edit-offer-step6']}
      />
    ),
  },
]

export const homeSteps: Step[] = [
  {
    selector: '[data-step="step-0"]',
    title: 'Step 0 - WELCOME',
    content: <StepContent step="step0" defaultValue={home.step0} />,
    action: (node) => {
      const buttons = node?.querySelectorAll('button')
      let switchButton = null as null | HTMLButtonElement
      buttons?.forEach((element) => {
        if (element?.textContent?.includes('Tab view')) switchButton = element
      })

      const waitingRoomTab = node?.querySelector(
        `[aria-controls="${InventoryTabKey.WaitingRoom}"]`,
      ) as HTMLButtonElement
      if (switchButton?.textContent === 'Tab view') switchButton.click()
      waitingRoomTab?.click()
    },
  },
  {
    selector: '[data-step="step-1"]',
    title: 'Step 1 - New table and NEW status',
    content: <StepContent step="step1" defaultValue={home.step1} />,
  },
  {
    selector: '[data-step="step-2"]',
    title: 'Step 2 - Selecting items',
    content: <StepContent step="step2" defaultValue={home.step2} />,
    action: (node) => {
      const nodeAux = node as HTMLInputElement
      if (!nodeAux.checked) {
        nodeAux.click()
      }
    },
  },
  {
    selector: '[data-step="step-3"]',
    title: 'Step 3 - Save changes',
    content: (
      <StepContent
        step="edit-offer-step4"
        defaultValue={editOffer['edit-offer-step4']}
      />
    ),
  },
  {
    selector: '[data-step="step-4"]',
    title: 'Step 4 - Open items',
    content: <StepContent step="step5" defaultValue={home.step4} />,
  },
  {
    selector: '[data-step="step-5"]',
    title: 'Step 5 - View store inventory',
    content: <StepContent step="step6" defaultValue={home.step5} />,
  },
]
