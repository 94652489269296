export const stepStyles = {
  badge: (props: any) => ({
    ...props,
    fontWeight: 'bold',
    color: '#ffffff',
    '--reactour-accent': '#000000',
  }),
  dot: (props: any) => ({
    ...props,
    fontWeight: 'bold',
    '--reactour-accent': '#000000',
  }),
}
