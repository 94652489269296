import { useRouter } from 'next/router'

import { editOfferSteps, homeSteps } from './steps'

export function Badge({ currentStep }: { currentStep: number }) {
  const router = useRouter()
  const { pathname } = router
  const currentSteps = pathname.includes('offers') ? editOfferSteps : homeSteps
  return currentSteps[currentStep].title
}
